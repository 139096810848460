* {
  box-sizing: border-box;
}

.scrollbar * {
  scrollbar-width: thin;
}
.scrollbar * ::-webkit-scrollbar {
  background-color: transparent;
  height: 0.5rem;
}
.scrollbar * ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

@media (min-width: 640px) {
  .scrollbar * ::-webkit-scrollbar {
    height: 0.8rem;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

/* Components */
.react-math-keyboard-input-container {
  font-size: 1rem;
  box-sizing: border-box;
  overflow: hidden;
}
@media (min-width: 768px) {
  .react-math-keyboard-input-container {
    font-size: 1.1rem;
  }
}
@media (min-width: 1440px) {
  .react-math-keyboard-input-container {
    font-size: 1.4rem;
  }
}

.react-math-keyboard-input {
  flex-grow: 1;
  /* width: 100%; */
  border-radius: 4px;
  border-color: #fff;
  align-items: center;
  display: flex;
  scroll-margin-top: 24px;
  box-sizing: border-box;
  overflow: auto;
  /* overflow: hidden; */
  min-width: 4em;
  /* margin: 2px; */
  /* max-width: 100%; */
  outline: 0 !important;
}

.react-math-keyboard-keyboard-container {
  width: 100%;
  padding-bottom: 0.25rem;
  margin: 0px;
  color: #020617;
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: -300px;
  left: 0px;
  z-index: 1310;
  transition: bottom 300ms ease-in-out;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
  /* transition: bottom 300ms cubic-bezier(0.4,0,0.2,1); */
}

.react-math-keyboard-keyboard-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.react-math-keyboard-numeric-layout {
  width: 100%;
  max-width: 480px;
  padding: 0.25rem;
  padding-top: 0.5rem;
  display: grid;
  column-gap: 0.75rem;
  /* grid-template-columns: 2fr 3fr 1fr; */
  grid-template-columns: 3fr 1fr;
}
@media (min-width: 640px) {
  .react-math-keyboard-numeric-layout {
    column-gap: 2.5rem;
  }
}
.react-math-keyboard-alphabet-layout {
  width: 100%;
  max-width: 768px;
  padding: 0.25rem;
  padding-top: 0.55rem;
  row-gap: 0.25rem;
  display: flex;
  flex-direction: column;
}

.react-math-keyboard-layout-grid {
  display: grid;
  column-gap: 0.25rem;
  row-gap: 0.25rem;
  justify-content: stretch;
}
@media (min-width: 640px) {
  .react-math-keyboard-layout-grid {
    column-gap: 0.5rem;
  }
}

.react-math-keyboard-select-container {
  border-radius: 0.25rem;
  /* padding-right: 0.25rem; */
  margin-bottom: 0.25rem;
  display: flex;
  background-color: #f8fafc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  position: relative;
}
.react-math-keyboard-select-container:focus-visible {
  outline: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-math-keyboard-select {
  border: transparent;
  border-radius: 0.25rem;
  background-color: transparent;
  appearance: none;
  color: #020617;
  -webkit-appearance: none;
  /* border-right-width: 16px; */
  padding-left: 0.5rem;
  /* padding-right: 0.25rem; */
  padding-right: 1.75rem;
  font-size: 1.1rem;
}
.react-math-keyboard-select:focus-visible {
  outline: none;
}

.react-math-keyboard-select-arrow {
  position: absolute;
  transform: rotate(90deg);
  top: 22px;
  right: 8px;
  pointer-events: none !important;
}

.react-math-keyboard-toolbar-container {
  background-color: #94a3b8;
  padding-top: 0.5rem;
  /* align-items: center; */
  width: 100%;
}
.react-math-keyboard-toolbar {
  display: flex;
  max-width: 768px;
  column-gap: 0.5rem;
  justify-content: space-between;
  padding-right: 0.25rem;
  margin: auto;
}
@media (min-width: 768px) {
  .react-math-keyboard-toolbar {
    min-width: 768px;
  }
}
.react-math-keyboard-divider {
  border: transparent;
  border-top: 2px solid #64748b;
  margin-top: 0.25rem;
  margin-bottom: 0px;
}

.react-math-keyboard-toolbar-keys-container {
  display: flex;
  column-gap: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.25rem;
}

.react-math-keyboard-key {
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  color: #333333;
  height: 3rem;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: #f8fafc;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1)
}

.react-math-keyboard-key:focus-visible {
  outline: transparent;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-math-keyboard-key-utility {
  background-color: #fcf6d7;
}

@media not screen and (hover: none) and (pointer: coarse) {
  .react-math-keyboard-key:hover {
    background-color: #ECECEC;
  }
  .react-math-keyboard-key-utility:hover {
    background-color: #FCF699;
  }
}

.mq-math-mode var {
  font-family: Epilogue !important;
  font-style: normal !important;
}